import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { UpdateDriverSelectionRequestV2 } from '@aaa-ncnu-ie/ez-quote-api-driver';
import { ContainerProps } from '@mui/material';
import { useActionCreators } from 'api/actions';
import useRequest from 'api/makeRequest';
import {
  AddDriverResponse,
  Driver as DriverAPI,
  DriverIncidentItem,
  GetAssignmentResponse,
  GetDrivingHistoryResponse,
  GetQuoteIncidentsResponse,
  MetadataResponse,
  SaveAssignmentRequest,
  UpdateDriverResponse,
} from 'api/schema/driver.schema';
import { PolicyType } from 'api/schema/insured.schema';
import { OnHydrated } from 'helpers/form';
import { getNameByPath } from 'helpers/pages';
import { omitBy } from 'lodash';
import { DriverDescription } from 'components/forms/formTypes';
import { useGetAllDrivers } from '../../api/queries/driver/useGetAllDrivers';
import { useGetDriverMetadata } from '../../api/queries/driver/useGetDriverMetadata';
import { useUpdateSelectedDrivers } from '../../api/queries/driver/useUpdateSelectedDrivers';
import { InsuredContext } from './InsuredContext';

export type IncidentItem = {
  incident: DriverIncidentItem;
  driver:
    | {
        value: string;
        displayValue: string;
      }
    | null
    | undefined;
};
export type IncidentList = Array<IncidentItem>;

export type DriverIncident = {
  driverId: string;
  firstName: string;
  lastName: string;
  incidents: DriverIncidentItem[];
};

export interface DriverContextState {
  drivers: DriverDescription[];
  assignment: GetAssignmentResponse | any;
  incidents?: IncidentList;
  fetched: boolean;
  incidentsFetched?: boolean;
  incidentsPrefilled?: boolean;
  drivingHistory?: GetDrivingHistoryResponse;
  driverRemovalReasonEnabled: boolean;
}

export interface DriverNavigationContextState {
  driversVisited: (string | undefined)[];
}

export type DriverContextType = {
  driverContextState: DriverContextState;
  driverNavigationContextState: DriverNavigationContextState;
  fetchAllDrivers: () => Promise<DriverDescription[] | undefined>;
  fetchDriverAssignment: () => Promise<GetAssignmentResponse | undefined>;
  createDriver: (
    firstName: string,
    lastName: string,
  ) => Promise<AddDriverResponse | undefined>;
  updateDriver: (
    driver: Partial<DriverDescription>,
  ) => Promise<DriverDescription[] | undefined>;
  updateDriverDl: (
    driver: Partial<DriverDescription>,
  ) => Promise<UpdateDriverResponse | undefined>;
  selectDrivers: (
    drivers: UpdateDriverSelectionRequestV2,
  ) => Promise<DriverDescription[] | undefined>;
  getDriversById: (driverIds: string[]) => DriverDescription[];
  getDriverIndex: (driverId?: string | null) => number;
  updateDriversVisited: (driverIds: (string | undefined)[]) => void;
  updateDriverAssignment: (
    saveAssignmentRequest: SaveAssignmentRequest,
  ) => Promise<undefined>;
  addIncidents: (
    incidents: {
      incident: DriverIncidentItem;
      driver:
        | {
            value: string;
            displayValue: string;
          }
        | null
        | undefined;
    }[],
  ) => void;
  setIncidents: (incidents: IncidentList) => void;
  setCompletedDl: (
    driver: Partial<DriverDescription> | undefined,
    updateFlag: boolean,
    reset?: boolean,
  ) => void;
  driverMetaData: MetadataResponse | undefined;
  isLoading: boolean;
  updateNationalOriginForPNI: (
    driver: Partial<DriverDescription>,
  ) => Promise<DriverDescription[] | undefined>;
};

const defaultDriverContextState: DriverContextState = {
  drivers: [],
  assignment: { type: '', assignment: [] },
  incidents: undefined,
  fetched: false,
  incidentsFetched: false,
  drivingHistory: undefined,
  driverRemovalReasonEnabled: false,
};

const defaultDriverNavigationContextState: DriverNavigationContextState = {
  driversVisited: [],
};

export const DriverContext = createContext<DriverContextType>({
  driverContextState: defaultDriverContextState,
  driverNavigationContextState: defaultDriverNavigationContextState,
  fetchAllDrivers: () => Promise.resolve(undefined),
  fetchDriverAssignment: () => Promise.resolve(undefined),
  createDriver: () => Promise.resolve(undefined),
  updateDriver: () => Promise.resolve(undefined),
  updateDriverDl: () => Promise.resolve(undefined),
  selectDrivers: () => Promise.resolve(undefined),
  getDriversById: () => [],
  getDriverIndex: () => -1,
  updateDriversVisited: () => undefined,
  updateDriverAssignment: () => Promise.resolve(undefined),
  setIncidents: () => undefined,
  setCompletedDl: () => Promise.resolve(undefined),
  addIncidents: () => undefined,
  driverMetaData: undefined,
  isLoading: false,
  updateNationalOriginForPNI: () => Promise.resolve(undefined),
});

const DriverContextProvider: React.FC<ContainerProps> = (props) => {
  const [driverContextState, setDriverContextState] = useState(
    defaultDriverContextState,
  );
  const [driverNavigationContextState, setDriverNavigationContextState] =
    useState(defaultDriverNavigationContextState);
  const [insuredContextState] = useContext(InsuredContext);
  const { insured } = insuredContextState;
  const history = useHistory();
  const makeRequest = useRequest();
  const {
    actionCreators: {
      getDrivingHistory,
      getDriverAssignment,
      createDriver,
      updateDriver,
      updateDriverAssignment,
      getAllIncidents,
      updateIncidents,
    },
  } = useActionCreators();

  const { data: driverMetaData, isLoading: isMetadataLoading } =
    useGetDriverMetadata();

  // Hydration on Refresh
  useEffect(() => {
    // It is only possible to get drivers on page refresh if and only if
    // user got past the address page and the "primary-insured"
    // object was created
    const isAuto = insured?.product === PolicyType.AUTO;
    if (insuredContextState.fetched && isAuto) {
      handleFetchAllDrivers();
      handleFetchAllIncidents();
    }
  }, [insuredContextState.fetched, insured?.product]);

  useEffect(() => {
    const pniDriver = driverContextState.drivers.find(
      (driver) => driver?.relationship === 'Self',
    );
    if (pniDriver) {
      makeRequest(() =>
        getDrivingHistory({
          driverId: pniDriver?.driverId,
        }),
      ).then((historyResponse) => {
        if (historyResponse) {
          setDriverContextState((prevState) => ({
            ...prevState,
            incidentsPrefilled: historyResponse.incidentsPrefilled,
            drivingHistory: historyResponse,
          }));
        }
      });
    }
  }, [driverContextState.fetched]);

  // Hydration on Refresh
  useEffect(() => {
    // This is important to hydrate selected drivers
    // at this stage because it is required for the navigation
    // menu to be populated
    if (driverContextState.drivers) {
      const driverIds = driverContextState.drivers
        .filter(({ selected, driverId }) => selected && driverId)
        .map(({ driverId }) => driverId!);
      OnHydrated.publish({
        driverIds,
      });
    }
  }, [driverContextState.fetched]);

  useEffect(() => {
    const pageName = getNameByPath(history?.location?.pathname);
    const pageList = [
      'CommercialPurposes',
      'CompanyInfo',
      'GaragingAddressConfirmation',
      'VehiclesGaragingAddress',
      'VinCollectPageForm',
    ];

    if (pageList.includes(pageName)) {
      handleUpdateDriverCompletedDl(undefined, false, true); // Reset completed DL flag on browser nav back flow
    }
  }, [history?.location?.pathname]);

  const { refetch: refetchAllDrivers } = useGetAllDrivers({
    onSuccess: ({ drivers, driverRemovalReasonEnabled }) => {
      setDriverContextState((prevState) => ({
        ...prevState,
        drivers,
        fetched: true,
        driverRemovalReasonEnabled,
      }));
    },
    options: {
      enabled: false,
    },
  });

  const handleFetchAllDrivers = useCallback(async (): Promise<
    DriverDescription[] | undefined
  > => {
    const { data } = await refetchAllDrivers({
      throwOnError: true,
      cancelRefetch: false,
    });
    return data?.drivers;
  }, [refetchAllDrivers]);

  const handleFetchAllIncidents = (): Promise<
    GetQuoteIncidentsResponse | undefined
  > => {
    return makeRequest(getAllIncidents).then(
      (incidents: GetQuoteIncidentsResponse | undefined) => {
        if (!incidents) return Promise.resolve(undefined);

        setDriverContextState((prevState) => ({
          ...prevState,
          incidentsFetched: true,
          incidents: incidents.driverIncidents
            ?.map((driverIncident: DriverIncident) => {
              return driverIncident?.incidents?.map(
                (incident: DriverIncidentItem) => ({
                  incident,
                  driver: {
                    value: driverIncident?.driverId,
                    displayValue: `${driverIncident?.firstName} ${driverIncident?.lastName}`,
                  } as IncidentItem['driver'],
                }),
              );
            })
            ?.flat(),
        }));
        return incidents;
      },
    );
  };

  const handleUpdateIncidents = (incidents: IncidentList) => {
    setDriverContextState((prevState) => ({
      ...prevState,
      incidents,
    }));
  };

  useEffect(() => {
    if (
      driverContextState?.drivers?.length &&
      driverContextState?.incidents?.length
    )
      syncIncidents();
  }, [driverContextState.incidents]);

  const syncIncidents = (): Promise<any> => {
    if (driverContextState.incidents === undefined) {
      return Promise.resolve(undefined);
    }

    const activeDrivers = driverContextState.drivers.filter(
      ({ selected }) => selected,
    );

    return Promise.all(
      activeDrivers.map(({ driverId }) =>
        makeRequest(() => {
          const incidentList: Array<DriverIncidentItem> =
            driverContextState.incidents
              ?.filter(
                (incidentItem) => incidentItem.driver?.value === driverId,
              )
              .map((incidentItem) => incidentItem.incident) || [];
          return updateIncidents({ incidentList }, { driverId: driverId! });
        }),
      ),
      // will throw if quote has not yet been created, but we shouldn't need to sync yet
    ).catch((e) => console.log(e));
  };

  const handleAddIncidents = (
    incidents: {
      incident: DriverIncidentItem;
      driver:
        | {
            value: string;
            displayValue: string;
          }
        | null
        | undefined;
    }[],
  ) => {
    const incidentList = (driverContextState?.incidents as IncidentList) || [];
    handleUpdateIncidents([...incidentList, ...incidents]);
  };

  const handleCreateDriver = async (
    firstName: string,
    lastName: string,
  ): Promise<AddDriverResponse | undefined> => {
    //Make request to create driver
    const createDriverResponse = await makeRequest(() =>
      createDriver({ firstName, lastName }),
    );
    // Return early if we are not able to create the driver
    if (!createDriverResponse) return Promise.resolve(undefined);

    await handleFetchAllDrivers();

    return createDriverResponse;
  };

  const handleUpdateDriver = async (
    driver: Partial<DriverDescription>,
  ): Promise<DriverDescription[] | undefined> => {
    const driverToUpdate = mapCustomDriverToDriver(driver);

    await makeRequest(() => updateDriver(driver.driverId!, driverToUpdate));

    return handleFetchAllDrivers();
  };

  const handleUpdateDriverDl = (
    driver: Partial<DriverDescription>,
  ): Promise<UpdateDriverResponse | undefined> => {
    setDriverContextState((prevState) => ({
      ...prevState,
      drivers: prevState.drivers.map((d) => {
        if (d.driverId !== driver.driverId) return d;
        return { ...d, ...driver };
      }),
    }));
    const driverToUpdate = mapCustomDriverToDriver(driver);
    return updateDriver(driver.driverId!, driverToUpdate);
  };

  const { mutateAsync: updateSelectedDrivers } = useUpdateSelectedDrivers({
    onSuccess: ({ drivers, driverRemovalReasonEnabled }) => {
      setDriverContextState((prevState) => ({
        ...prevState,
        drivers,
        driverRemovalReasonEnabled,
      }));
    },
  });
  const handleSelectDrivers = useCallback(
    async (
      drivers: UpdateDriverSelectionRequestV2,
    ): Promise<DriverDescription[] | undefined> => {
      const updatedDrivers = await updateSelectedDrivers(drivers);

      return updatedDrivers.drivers;
    },
    [updateSelectedDrivers],
  );

  const handleGetDriversById = (driverIds: string[]): DriverDescription[] => {
    return driverContextState.drivers.filter((driver) =>
      driverIds.includes(driver.driverId!),
    );
  };

  const handleUpdateDriversVisited = (driverIds: (string | undefined)[]) => {
    setDriverNavigationContextState({
      driversVisited: [...driverIds],
    });
  };

  const handleFetchDriverAssignment = (): Promise<
    GetAssignmentResponse | undefined
  > => {
    return makeRequest(getDriverAssignment).then(
      (driverAssignmentResponse: GetAssignmentResponse | undefined) => {
        if (!driverAssignmentResponse) return Promise.resolve(undefined);

        setDriverContextState((prevState) => ({
          ...prevState,
          assignment: driverAssignmentResponse,
        }));

        return driverAssignmentResponse;
      },
    );
  };

  const handleUpdateDriverAssignment = (body: any): Promise<undefined> => {
    return makeRequest(() => updateDriverAssignment(body));
  };

  const handleUpdateDriverCompletedDl = (
    driver: Partial<DriverDescription> | undefined,
    updateFlag: boolean,
    reset?: boolean,
  ) => {
    if (reset) {
      handleFetchAllDrivers();
    } else {
      setDriverContextState((prevState) => ({
        ...prevState,
        drivers: prevState.drivers.map((d) => {
          if (d.driverId !== driver?.driverId) return d;
          return { ...d, ...driver, completedDl: updateFlag };
        }),
      }));
    }
  };

  const driverIndexes = useMemo(
    () =>
      driverContextState.drivers
        .sort((a, b) => (a.driverId ?? '').localeCompare(b?.driverId ?? ''))
        .reduce<Record<string, number>>(
          (previousValue, currentValue, currentIndex) => ({
            ...previousValue,
            [currentValue.driverId ?? '']: currentIndex,
          }),
          {},
        ),
    [driverContextState.drivers],
  );

  const getDriverIndex = (driverId?: string | null) => {
    if (driverId == null) {
      return driverContextState.drivers.length;
    }

    return driverIndexes[driverId];
  };

  const handleNationalOriginPNI = async (
    driver: Partial<DriverDescription>,
  ): Promise<DriverDescription[] | undefined> => {
    // Only pass nationalOrigin to the update
    const driverToUpdate = {
      nationalOrigin: driver.nationalOrigin,
      drivingLicense: driver.drivingLicense,
    };
    await makeRequest(() => updateDriver(driver.driverId!, driverToUpdate));
    return undefined;
  };

  return (
    <DriverContext.Provider
      value={{
        driverContextState,
        driverNavigationContextState,
        driverMetaData,
        fetchAllDrivers: handleFetchAllDrivers,
        fetchDriverAssignment: handleFetchDriverAssignment,
        createDriver: handleCreateDriver,
        updateDriver: handleUpdateDriver,
        updateDriverDl: handleUpdateDriverDl,
        selectDrivers: handleSelectDrivers,
        getDriversById: handleGetDriversById,
        getDriverIndex,
        updateDriversVisited: handleUpdateDriversVisited,
        updateDriverAssignment: handleUpdateDriverAssignment,
        addIncidents: handleAddIncidents,
        setIncidents: handleUpdateIncidents,
        setCompletedDl: handleUpdateDriverCompletedDl,
        isLoading: isMetadataLoading,
        updateNationalOriginForPNI: handleNationalOriginPNI,
      }}
    >
      {props.children}
    </DriverContext.Provider>
  );
};

export default DriverContextProvider;

const mapCustomDriverToDriver = (driver: DriverDescription): DriverAPI => {
  const isDriverPNI = driver.relationship?.toLowerCase() === 'self';
  return omitBy(
    {
      ageFirstLicensed: driver.ageFirstLicensed,
      gender: driver.gender,
      relationship: !isDriverPNI ? driver.relationship : undefined,
      birthDate: !isDriverPNI
        ? driver.birthDate?.format('YYYY-MM-DD')
        : undefined,
      enrollInUBI: driver.enrollInUBI,
      emailUBIDriver: driver.emailUBIDriver,
      mobilePhoneUBIDriver:
        driver?.mobilePhoneUBIDriver && driver.mobilePhoneUBIDriver.trim() != ''
          ? driver.mobilePhoneUBIDriver
          : undefined,
      smsConsent: driver.smsConsent,
      drivingLicense: driver.drivingLicense,
    },
    (prop) => prop === undefined,
  );
};
