import React, { createContext, useContext, useEffect, useState } from 'react';
import { AiConfig } from '@aaa-ncnu-ie/ez-quote-session-types';
import { ContainerProps } from '@mui/material';
import { useActionCreators } from 'api/actions';
import useRequest from 'api/makeRequest';
import { parsePostalCode } from 'helpers/address';
import { getCookie } from 'helpers/cookies';
import { setGtmDataLayer } from 'helpers/gtm';
import useUrlQuery from 'hooks/useUrlQuery';
import { formatPhoneNumber } from '../../helpers/phone';
import { InsuredContext } from './InsuredContext';

export interface ClubContextState {
  clubcode: string;
  state: string;
  supportPhoneNo: string;
  formatedPhonenNumber: string;
  officeLink: string;
  bundleMonthlyDiscount?: string;
  promocode: string;
  source: string;
  dmlpid: string;
  featureFlags?: any;
  aiConfig?: AiConfig;
}

export type SetClubContextState = (newState: ClubContextState) => void;
export type UpdateClubContextState = (
  updatedProps: Partial<ClubContextState>,
) => void;
export type ResetClubContextState = () => void;

export type ClubContextType = {
  clubState: ClubContextState;
  setClubState: SetClubContextState;
  updateClubState: UpdateClubContextState;
  resetClubState: ResetClubContextState;
};

const defaultContextState: ClubContextState = {
  clubcode: '',
  state: '',
  supportPhoneNo: '',
  formatedPhonenNumber: '',
  officeLink: 'https://www.aaa.com/stop/',
  bundleMonthlyDiscount: '$12/month',
  promocode: '',
  source: 'club',
  dmlpid: '',
};

export const ClubContext = createContext<ClubContextType>({
  clubState: defaultContextState,
  setClubState: () => undefined,
  updateClubState: () => undefined,
  resetClubState: () => undefined,
});

const ClubContextProvider: React.FC<ContainerProps> = (props) => {
  const [clubState, setClubState] = useState(defaultContextState);
  const [{ fetched, insured }] = useContext(InsuredContext);
  const { source } = clubState;
  const { getParam, getParamFromCookie } = useUrlQuery();
  const queryParam = getCookie('queryParam');
  const promocode =
    getParam('promocode') ||
    getParamFromCookie(queryParam, 'promocode', null) ||
    clubState.promocode;

  const makeRequest = useRequest();
  const resetClubState = () => setClubState(defaultContextState);
  const {
    actionCreators: { getClubCode },
  } = useActionCreators();

  const updateClubState = (updatedProps: Partial<ClubContextState>) =>
    setClubState((prevState) => {
      const nextState = { ...prevState, ...updatedProps };

      if (updatedProps?.supportPhoneNo) {
        nextState.formatedPhonenNumber = formatPhoneNumber(
          updatedProps.supportPhoneNo,
        );
        setGtmDataLayer({ support_phone_number: updatedProps.supportPhoneNo });
      }
      return nextState;
    });

  useEffect(() => {
    if (fetched && insured) {
      const policyType = insured.product;
      const { postalCode } = parsePostalCode(insured.address);
      makeRequest(
        () =>
          getClubCode({
            policyType,
            postalCode,
            source: getCookie('source') || source,
            ...(promocode && { promocode: promocode }),
          }),
        ({
          clubCode,
          state,
          featureFlags,
          aiConfig,
          bundleMonthlyDiscount,
          supportPhoneNo,
          officeLink,
        }) => {
          updateClubState({
            state: state,
            clubcode: clubCode,
            featureFlags,
            aiConfig,
            bundleMonthlyDiscount,
            supportPhoneNo,
            officeLink,
            promocode,
          });
        },
      );
    }
  }, [fetched, insured?.product]);

  const providerValue = {
    clubState,
    setClubState,
    updateClubState,
    resetClubState,
  };

  return (
    <ClubContext.Provider value={providerValue}>
      {props.children}
    </ClubContext.Provider>
  );
};

export default ClubContextProvider;
