import {
  QuoteType,
  ThirdPartyAddress,
} from '@aaa-ncnu-ie/ez-quote-session-types';
import {
  PaymentPlanType,
  RelationshipToPniCd,
} from '@aaa-ncnu-ie/ez-quote-session-types';
import { DriverDiscount } from 'api/schema/discounts.schema';
import {
  AgeFirstLicensed,
  DriverAdditionalInfo,
  FirstName,
  Gender,
  LastName,
  SaveAssignment,
  Source as DriverSource,
} from 'api/schema/driver.schema';
import { PolicyType, ResidenceType } from 'api/schema/insured.schema';
import { QualityGrade } from 'api/schema/property.schema';
import { Source as VehicleSource } from 'api/schema/vehicle.schema';
import { supportedStates } from 'helpers/address';
import moment from 'moment';

// note: it would be great if we could eliminate a separate type for the server POST
// that might be a little tricky in some scenarios
export type DriverDescription = Omit<DriverAdditionalInfo, 'birthDate'> & {
  firstName?: FirstName;
  lastName?: LastName;
  relationship?: RelationshipToPniCd;
  birthDate?: moment.Moment | null | undefined;
  driverId?: string;
  gender?: Gender;
  maritalStatus?: string;
  occupation?: string;
  ageFirstLicensed?: AgeFirstLicensed;
  selected?: boolean;
  completed?: boolean;
  completedDl?: boolean;
  source?: DriverSource;
};

export type AssignmentDescription = SaveAssignment;

export type VehicleDescription = {
  source?: VehicleSource;
  year?: string;
  make?: string;
  model?: string;
  vin?: string;
  series?: string;
  bodyStyle?: string;
  usageType?: string; //'pleasure' | 'commute' | 'business';
  ownership?: string; //'owned' | 'leased' | 'financed';
  calcAnnualMiles?: boolean;
  annualMiles?: string | null;
  milesOneWay?: string | null;
  odometerReading?: string | null;
  isRideshare?: boolean;
  rideshareDaysPerWeek?: string | null;
  rideshareRidesPerWeek?: string | null;
  vehicleId?: string;
  selected?: boolean;
  completed?: boolean;
  completedCompanyInfo?: boolean;
  skippedCompanyInfo?: boolean;
  antiTheft?: string;
  daytimeRunningLights?: boolean;
  antiLockBrakes?: boolean;
  driveLessThan1000?: boolean;
  businessUsage?: string | null;
  thirdPartyAddress?: ThirdPartyAddress;
  addedUsingVin?: boolean;
};

export type MultiPolicyDiscount = 'Multipolicy Discount';

export type DiscountType = {
  discountName: MultiPolicyDiscount | DriverDiscount;
  percentDiscount: number;
  selected?: boolean;
  eligibleDrivers?: {
    driverId: string;
    firstName: string;
    lastName: string;
    selected: boolean;
  };
  eligibleVehicles?: {
    vehicleId: string;
    vehIdentificationNo: string;
    year: number;
    make: string;
    model: string;
    selected: boolean;
  };
};

export enum VehicleUsageType {
  Pleasure = 'Pleasure',
  WorkCommute = 'WorkCommute',
  Business = 'Business',
}

export enum TGender {
  Male = 'male',
  Female = 'female',
  NonConforming = 'nonConforming',
}

export enum VehicleOwnership {
  Owned = 'OWN',
  Leased = 'LSD',
  Financed = 'FNC',
}

export enum SaveIncidentRequestCategoryType {
  Minor = 'Minor Traffic Violation',
  Major = 'Major Traffic Violation',
  AlcoholRelatedViolation = 'Driving Under the Influence',
  AtFaultNoInjury = 'At-Fault Accident, no Injury',
  AtFaultWithInjury = 'At-Fault Accident with Injury',
}

export enum ActivityType {
  PAGE_CONTINUE = 'PAGE_CONTINUE',
  MOUSE_CLICK = 'MOUSE_CLICK',
}

export interface BypassAddress {
  streetAddress: string;
  streetAddress2?: string;
  city: string;
  state: (typeof supportedStates)[number] | null;
  zip: string;
  county?: string;
  countyOptions?: [string, string][];
}

export interface RetrieveQuoteDetails {
  email: string;
  zipcode: string;
  dateOfBirth: Date | null | undefined;
}

// export enum PaymentMethodType {
//   DebitCard = 'debitCard',
//   DebitOrCreditCard = 'debitOrCreditCard',
//   CheckingAccount = 'checkingAccount',
//   SavingsAccount = 'savingsAccount',
// }

export enum PaymentMethodType {
  DebitCard = 'debit-card',
  DebitOrCreditCard = 'card',
  CheckingAccount = 'checking-account',
  SavingsAccount = 'savings-account',
}

export interface FormValues {
  retrieveQuote: RetrieveQuoteDetails;
  product: PolicyType | undefined;
  zipcode: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date | null | undefined;
  maritalStatus: string | null;
  occupation: string | null;
  coapplicant: boolean;
  address: string;
  addressZip: string;
  apartment: string;
  township?: string;
  previousAddress: string | null;
  previousApartment?: string;
  previousCounty?: string;
  previousTownship?: string;
  bypassAddress: {
    current: BypassAddress;
    previous: BypassAddress;
    garagingAddress: BypassAddress;
    mailingAddress: BypassAddress;
    thirdPartyAddress: BypassAddress;
  };
  garagingAddress: string;
  garagingApartment: string;
  mailingAddress: string;
  mailingApartment: string;
  thirdPartyAddress: string;
  thirdPartyApartment: string;
  lineHolderName: string;
  lineHolderNameOther: string;
  vin: string;
  year: string;
  make: string | null;
  model: string | null;
  vehicleIds: string[];
  commercialVehicleIds: string[];
  discountOption: boolean | undefined;
  propertyBundleOption: boolean | undefined;
  propertyClaimsOption: boolean | undefined;
  residenceType: ResidenceType | undefined;
  fireAlarmType: string | undefined;
  theftAlarmType: string | undefined;
  isSprinkler: boolean | undefined;
  vehicleOwnership: VehicleOwnership | undefined;
  vehicleUsageType: VehicleUsageType | undefined;
  isRideshare: boolean | undefined;
  daytimeRunningLights: boolean | undefined;
  antiLockBrakes: boolean | undefined;
  driveLessThan1000: boolean | undefined;
  rideshareDaysPerWeek: string;
  rideshareRidesPerWeek: string;
  annualMiles: string;
  calcAnnualMiles: boolean;
  milesOneWay: string;
  odometerReading: string;
  driverIds: string[];
  ageFirstLicensed: string;
  birthDate: Date | null | undefined;
  relationship:
    | RelationshipToPniCd.SPOUSE
    | RelationshipToPniCd.CHILD
    | RelationshipToPniCd.PARENT
    | RelationshipToPniCd.OTHER
    | undefined
    | null;
  gender: string | null;
  email: string;
  cellNumber: string;
  isPrimaryResidence: string;
  occupancyType?: string;
  noOfClaims: string | undefined;
  squareFootage: number | '';
  yearBuilt: number | '';
  qualityGrade: QualityGrade | undefined;
  isInsuredLastSixMonths: boolean | null;
  currentCarrierCompany: string;
  yearsWithPriorInsurer: string;
  priorBiLimit: string;
  incidents: Array<any>;
  hasIncidents: boolean | undefined;
  studentDiscounts: string[]; // Contains an array of DriverDescription.driverId
  smartDriverDiscounts: string[]; // Contains an array of DriverDescription.driverId
  defensiveDriverDiscounts: string[]; // Contains an array of DriverDescription.driverId
  travelinkDiscounts: string[]; // Contains an array of DriverDescription.driverId
  isLongtermDwelling: boolean | null;
  shareTelematicsDrivingData: string | null;
  isRetirementCommunity: boolean | null;
  numberResidents?: string;
  driverAssignment?: Array<any>;
  incidentDriver: {
    displayValue: string;
    value: string;
  } | null;
  incidentType: SaveIncidentRequestCategoryType | null;
  incidentYear: string;
  antiTheft: string | undefined;
  primaryInsurer: string;
  healthInsurerName: string;
  healthInsurerPolicyNumber: string;
  maskedHealthInsurerPolicyNumber: string;
  medicalExpLimit: string;
  isHealthInsurance: boolean | null;
  isGaragingAddress: boolean | null;
  isMailingAddress: boolean | null;
  isMailingPOBox: boolean | null;
  isPOBox: boolean | null;
  poBoxAddress: {
    pobox: string;
    city: string;
    state: (typeof supportedStates)[number] | null;
    zip: string;
  };
  poBoxMailingAddress: {
    pobox: string;
    city: string;
    state: (typeof supportedStates)[number] | null;
    zip: string;
  };
  effectiveDate: string | null | undefined;
  paperlessEnrolled: boolean | undefined;
  textEnrolled: boolean | undefined;
  paperlessEmail: string;
  textAlertsPhone: string;
  evalueEnrolled: boolean | null;
  vehiclevin: string[];
  smartTrek: boolean | undefined;
  noUSALicense: boolean | null;
  driverLicense: string;
  maskedDriverLicense: string;
  driverId?: string;
  driverEmail: string;
  driverCellNumber: string;
  driverLicenseState: (typeof supportedStates)[number] | null;
  esignatureAgree: boolean | null;
  paymentPlans: PaymentPlanType | undefined;
  paymentMethod: PaymentMethodType | undefined;
  additionalInsured: {
    firstName: string;
    lastName: string;
    birthDate: Date | null | undefined;
  };
  landlordDetails: {
    additionalInterestName: string;
    address: {
      addressLine1: string;
      addressLine2?: string;
      city: string;
      stateProvCd: string;
      postalCode: string;
      county?: string;
      countyName?: string;
      township?: string;
      addressValidated?: boolean;
      isPoBox?: boolean;
    };
  };
  constructionType: string | undefined;
  noFamilyUnits: string | undefined;
  dwellingDetails: string | undefined;
  numberOfStories: string | undefined;
  garageType: string | undefined;
  noFireplaces: number | undefined;
  foundationType: string | undefined;
  exteriorWallFinish: string | undefined;
  quoteType: QuoteType;
  isMembershipAutoRenew: boolean | undefined;
  memberIds: string[];
}

export type DriverLicenseFormValues = {
  noUSALicense?: FormValues['noUSALicense'];
  driverLicense?: FormValues['driverLicense'];
  maskedDriverLicense?: FormValues['maskedDriverLicense'] | undefined;
  driverLicenseState?: FormValues['driverLicenseState'];
  smartTrek?: FormValues['smartTrek'];
  driverEmail?: FormValues['driverEmail'];
  driverCellNumber?: FormValues['driverCellNumber'];
  driverId?: FormValues['driverId'];
};
