import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PolicyType } from '@aaa-ncnu-ie/ez-quote-session-types';
import { ContainerProps } from '@mui/material';
import { useActionCreators } from 'api/actions';
import useRequest from 'api/makeRequest';
import {
  Address,
  GetAdditionalInsuredResponse,
  GetPrimaryInsuredResponse,
  MetadataResponse,
  ResidenceType,
} from 'api/schema/insured.schema';
import { LandlordRequestResponse } from 'api/schema/property.schema';
import { parseAddress, parsePoBox, supportedStates } from 'helpers/address';
import { getCookie } from 'helpers/cookies';
import { useDeviceTypes } from 'helpers/devices';
import { OnHydrated } from 'helpers/form';
import { serverDateFormatter } from 'helpers/formatter';
import { setGtmDataLayer } from 'helpers/gtm';
import { getProductType } from 'helpers/policy-and-residence';
import { ClubContext } from 'components/contexts/ClubContext';
import { resolveCurrentCarrierPageVisibility } from '../../helpers/current-carrier';
import { formatPhoneNumberAlternative } from '../../helpers/phone';
import useUrlQuery from '../../hooks/useUrlQuery';

export type InsuredContextState = {
  // "isLexisNexisNoHit" is a negation of "isCurrentCarrier",
  // however, I need to set it so that it is only
  // available after the thinking page transition in
  // the normal (non-refresh) flow
  isLexisNexisNoHit?: boolean;
  insured?: {
    firstName: string;
    lastName: string;
    dateOfBirth: Date | null;
    address: string;
    product: NonNullable<GetPrimaryInsuredResponse['policyType']>;
    isCurrentCarrier?: boolean;
    addressLine1?: string;
    addressLine2?: string;
    residenceType?: ResidenceType;
    maritalStatus?: string;
    occupation?: string;
    email?: string;
    phoneNumber?: string;
    aaaMember?: boolean;
  };
  supportPhoneNo?: string;
  officeLink?: string;
  state?: string;
  county?: string;
  householdPrefilled?: boolean;
  isFresh?: boolean;
  fetched?: boolean;
  effectiveDate?: string;
  chatEnabled?: boolean;
  addressValidated?: boolean;
  bindEnabled?: boolean;
  /** derived value from bundleAllowed indicating that there is at least one PolicyType enabled for bundle */
  isBundleFlow?: boolean;
  carcoEnabled?: boolean;
  lessorLienholderAutoPopulate?: boolean;
  mandatoryViewPolicyDocuments?: boolean;
  mailingAddress?: Address | null;
  additionalInsured?: GetAdditionalInsuredResponse | null;
  landlordDetails?: LandlordRequestResponse | null;
  membershipPurchaseEnabled?: boolean;
  membershipDiscountOptInEnabled?: boolean;
  advanceShoppingDiscount?: number;
  featureFlags?: {
    currentCarrierSinglePage?: boolean;
    telematicsEnabled: boolean;
    telematicsV2Enabled: boolean;
    smartTrekEnabled?: boolean;
    haveAnAgentCallMeCTAEnabled: boolean;
  };
  bundleAllowed?: {
    [type in PolicyType]?: boolean;
  };
};

export type UpdateInsuredContextState = React.Dispatch<
  React.SetStateAction<InsuredContextState>
>;
export type UpdateMetaDataState = (
  newState: MetadataResponse | undefined,
) => void;

type InsuredContext = [
  InsuredContextState,
  UpdateInsuredContextState,
  MetadataResponse | undefined,
  UpdateMetaDataState,
];

export const InsuredContext = createContext<InsuredContext>([
  {},
  () => undefined,
  undefined,
  () => undefined,
]);

const convertAddressToString = ({
  addressLine1,
  addressLine2,
  city,
  stateProvCd,
  postalCode,
}: Address) =>
  `${addressLine1} ${
    addressLine2 ?? ''
  }, ${city}, ${stateProvCd} ${postalCode}`;

const InsuredContextProvider: React.FC<ContainerProps> = (props) => {
  const sessionId = getCookie('sessionId');
  const chatCookies = getCookie('chatToken');
  const initiatedQuote = getCookie('initiatedQuote');
  const { actionCreators } = useActionCreators();
  const [insuredState, setInsuredState] = useState<InsuredContextState>({});
  const [metaDataState, setMetaDataState] = useState<
    MetadataResponse | undefined
  >(undefined);
  const { segmentDeviceType } = useDeviceTypes();
  const { clubState } = useContext(ClubContext);
  const makeRequest = useRequest();
  const history = useHistory();
  const { getParam } = useUrlQuery();
  const {
    actionCreators: { getInsuredMetadata },
  } = useActionCreators();

  useEffect(() => {
    try {
      setGtmDataLayer({
        event: 'insuredUpdated',
        insured: {
          firstName: insuredState.insured?.firstName,
          lastName: insuredState.insured?.lastName,
          formattedAddress: insuredState.insured?.address,
          address: insuredState.insured?.address?.trim()
            ? parseAddress(insuredState.insured?.address)
            : undefined,
          email: insuredState.insured?.email,
          phoneNumber: insuredState.insured?.phoneNumber,
        },
      });
    } catch (e) {
      console.error('Error setting GTM data layer', e);
    }
  }, [
    insuredState.insured?.address,
    insuredState.insured?.email,
    insuredState.insured?.firstName,
    insuredState.insured?.lastName,
    insuredState.insured?.phoneNumber,
  ]);

  useEffect(() => {
    if (sessionId) {
      const effectiveDate = getParam('effectivedate');
      const shouldUpdateEffectiveDate =
        effectiveDate && effectiveDate !== insuredState.effectiveDate;
      setInsuredState({
        ...insuredState,
        isFresh: !initiatedQuote,
        ...(shouldUpdateEffectiveDate && { effectiveDate }),
      });
      const isHomeRoute = history.location.pathname === '/';
      const isRedirectRoute = history.location.pathname === '/redirect';
      const isErrorRoute = history.location.pathname === '/error';
      const isPurchaseConfirmation =
        history.location.pathname === '/purchase-confirmation';
      const hasQueryParams = history.location.search;
      const needsInitialization =
        (isHomeRoute && hasQueryParams) || isRedirectRoute;
      if (
        initiatedQuote &&
        sessionId &&
        !needsInitialization &&
        !isErrorRoute &&
        !isPurchaseConfirmation
      ) {
        makeRequest(() =>
          actionCreators.getInsured().then((insured) => {
            const product = getProductType(insured?.policyType);
            const firstName = insured?.firstName ?? '';
            const lastName = insured?.lastName ?? '';
            const addressLine1 = insured?.address?.addressLine1 ?? '';
            const addressLine2 = insured?.address?.addressLine2 ?? '';
            const city = insured?.address?.city ?? '';
            const stateProvCd = insured?.address?.stateProvCd ?? '';
            const zipcode = insured?.address?.postalCode ?? '';
            const county = insured?.address?.county ?? '';
            const township = insured?.address?.township ?? '';
            const addressValidated = insured?.address?.addressValidated;
            const dateOfBirth = serverDateFormatter(insured?.birthDate ?? '');
            const residenceType = insured?.residenceType;
            const supportPhoneNo = insured?.supportPhoneNo;
            const officeLink = insured?.officeLink;

            const isCurrentCarrier = resolveCurrentCarrierPageVisibility(
              insured?.currentCarrierPrefilled,
              product,
            );

            const currentCarrierSinglePage =
              !!insured?.currentCarrierSinglePage;
            const maritalStatus = insured?.maritalStatus;
            const occupation = insured?.occupation;
            const address = insured?.address
              ? `${addressLine1}, ${city}, ${stateProvCd} ${zipcode}`
              : '';
            const email = insured?.email ?? '';
            const phoneNumber = insured?.phoneNumber ?? '';
            const priorAddress = insured?.priorAddress;
            const previousAddress =
              insured?.priorAddress &&
              `${priorAddress?.addressLine1}, ${priorAddress?.city}, ${priorAddress?.stateProvCd} ${priorAddress?.postalCode}`;
            const previousApartment = insured?.priorAddress?.addressLine2 ?? '';
            const previousTownship = insured?.priorAddress?.township ?? '';
            const householdPrefilled = insured?.householdPrefilled;
            const bypassAddress = {
              current: {
                city: city,
                streetAddress: addressLine1,
                state: stateProvCd as (typeof supportedStates)[number] | null,
                zip: zipcode,
                county: county,
              },
              previous: {
                city: priorAddress?.city ?? '',
                streetAddress: priorAddress?.addressLine1 ?? '',
                state: (priorAddress?.stateProvCd ?? '') as
                  | (typeof supportedStates)[number]
                  | null,
                zip: priorAddress?.postalCode ?? '',
                county: priorAddress?.county ?? '',
              },
              garagingAddress: {
                city: '',
                streetAddress: '',
                state: null,
                zip: '',
                county: '',
              },
              mailingAddress: {
                city: '',
                streetAddress: '',
                state: null,
                zip: '',
                county: '',
              },
              thirdPartyAddress: {
                city: '',
                streetAddress: '',
                state: null,
                zip: '',
                county: '',
              },
            };
            const mailingAddress =
              insured?.mailingAddress && !insured?.mailingAddress?.isPOBox
                ? convertAddressToString(insured?.mailingAddress)
                : '';

            const poBoxMailingAddress = insured?.mailingAddress?.isPOBox
              ? {
                  pobox: parsePoBox(insured?.mailingAddress.addressLine1 ?? ''),
                  city: insured?.mailingAddress.city,
                  state: insured?.mailingAddress
                    .stateProvCd as (typeof supportedStates)[number],
                  zip: insured?.mailingAddress.postalCode,
                }
              : undefined;
            const aaaMember = insured?.aaaMember;
            const bundleAllowed = insured?.bundleAllowed;
            const isBundleFlow =
              !insured?.mpdAutoApplied &&
              !!Object.values(bundleAllowed ?? {}).find((v) => !!v);

            setInsuredState({
              ...insuredState,
              state: stateProvCd,
              county,
              householdPrefilled,
              insured: {
                product,
                address,
                addressLine1,
                addressLine2,
                dateOfBirth,
                firstName,
                lastName,
                isCurrentCarrier,
                residenceType,
                maritalStatus,
                occupation,
                email,
                phoneNumber,
                aaaMember,
              },
              supportPhoneNo,
              officeLink,
              fetched: true,
              isFresh: !initiatedQuote,
              chatEnabled: insured?.chatEnabled,
              addressValidated: addressValidated,
              bindEnabled: insured?.bindEnabled,
              carcoEnabled: insured?.carcoEnabled,
              lessorLienholderAutoPopulate:
                insured?.lessorLienholderAutoPopulate,
              mailingAddress: insured?.mailingAddress,
              membershipPurchaseEnabled: insured?.membershipPurchaseEnabled,
              membershipDiscountOptInEnabled:
                insured?.membershipDiscountOptInEnabled,
              advanceShoppingDiscount: insured?.advanceShoppingDiscount,
              featureFlags: {
                currentCarrierSinglePage,
                telematicsEnabled: !!insured?.telematicsEnabled,
                telematicsV2Enabled: !!insured?.telematicsV2Enabled,
                smartTrekEnabled: !!insured?.smartTrekEnabled,
                haveAnAgentCallMeCTAEnabled:
                  !!insured?.haveAnAgentCallMeCTAEnabled,
              },
              bundleAllowed: bundleAllowed,
              isBundleFlow,
            });

            setGtmDataLayer({
              chatEnabled: insured?.chatEnabled,
              first_driver_name: `${firstName} ${lastName}`,
              club_code: insured?.clubCode,
              state: stateProvCd,
              zipcode,
              ...(clubState.supportPhoneNo
                ? { support_phone_number: clubState.supportPhoneNo }
                : {}),
              promo_code: clubState.promocode,
              source: clubState.source,
              device_type: segmentDeviceType,
              policy_type: product,
              botId: chatCookies?.botId,
              clientId: chatCookies?.clientId,
              token: chatCookies?.token,
              maintenance: chatCookies?.maintenance,
              sessionId,
            });

            //history changed event is required to invoke chatbot, below line will help to invoke chat when retrive a quote
            window.dispatchEvent(new Event('HISTORY_CHANGED'));
            OnHydrated.publish({
              product,
              zipcode,
              firstName,
              lastName,
              dateOfBirth,
              address: addressValidated === false ? '' : address, //addressValidated flag is used for ADC flow
              apartment: addressLine2,
              addressZip: zipcode,
              township,
              previousAddress,
              previousApartment,
              previousTownship,
              residenceType,
              maritalStatus,
              occupation,
              bypassAddress,
              cellNumber: formatPhoneNumberAlternative(phoneNumber),
              email,
              mailingAddress,
              poBoxMailingAddress,
              isMailingAddress: insured.mailingAddressSelectedOption,
              isMailingPOBox: insured.mailingAddress?.isPOBox,
            });
          }),
        );
      }
    }
  }, [sessionId]);

  const { insured } = insuredState;
  useEffect(() => {
    if (
      insured &&
      insured.product &&
      insured.maritalStatus &&
      (clubState.state || insuredState.state) &&
      !metaDataState
    ) {
      getInsuredMetadata({
        state: insuredState.state || clubState.state,
        policyType: insured.product,
      }).then((data: MetadataResponse) => {
        setMetaDataState(data);
        const mappedMaritalValue = data.maritalStatus.find(
          (v) => v.value === insured.maritalStatus,
        )?.label;
        const mappedOccupationValue = data.occupation?.find(
          (v) => v.value === insured.occupation,
        )?.label;
        OnHydrated.publish({
          maritalStatus: mappedMaritalValue,
          occupation: mappedOccupationValue,
        });
      });
    }
  }, [
    metaDataState,
    insured?.product,
    insured?.maritalStatus,
    insured?.occupation,
    clubState.state,
    insuredState.state,
  ]);

  return (
    <InsuredContext.Provider
      value={[insuredState, setInsuredState, metaDataState, setMetaDataState]}
    >
      {props.children}
    </InsuredContext.Provider>
  );
};

export default InsuredContextProvider;
